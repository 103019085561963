@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system,
    BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gradient{
  background-color: #2a2b30;
  background-image: 
  radial-gradient(circle at top left, rgba(189, 47, 255, 0.771) 0%, transparent 37%),
  radial-gradient(circle at bottom right, #5546f9 0%, transparent 40%);
}
@layer utilities {
  .custom-transition {
    @apply transition duration-300;
  }
  .nav-links {
    @apply text-lg font-[500] custom-transition hover:text-yellow;
  }
  .nav-links.active{
    @apply text-yellow
  }
  .btn {
    @apply py-[14px] px-8 text-sm md:text-lg font-semibold border rounded-full custom-transition;
  }
  .info-text {
    @apply text-base md:text-lg leading-[27px] text-gray-dark;
  }
  .tag{
    @apply text-[13px] text-start bg-black-400 rounded-full py-2 px-[25px] inline-block font-semibold mb-[18px] md:mb-[30px]
  }
  .heading{
    @apply text-[35px] lg:text-[48px] xl:text-[61px] font-bold leading-[40px] lg:leading-[54px] xl:leading-[67.2px] mb-[30px]
  }
}
